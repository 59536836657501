<template>
  <div>
    <img class="banner d-lg-block d-none" src="~assets/images/img/careerdeveBanner.jpg"/>
    <div class="about container-fluid">
      <div class="row">
        <div class="col-12 col-lg-2" style="padding: 0">
          <switch-bar :switchList="switchList"
                      @switchItem="switchItem"
                      :activeIndex="Number(activeIndex)"/>
        </div>
        <div class="col-12 col-lg-9 aboutRight">
          <div class="rightTitle">
            <div class="rightTitleValue d-lg-block d-flex align-items-center">
              <p class="rightTitleValueC">{{showTitle}}</p>
              <p class="rightTitleValueE">{{showTitleE}}</p>
            </div>
            <div class="rightTitleLinks d-none d-lg-flex">
              <p>当前位置：</p>
              <router-link class="link" to="/home" tag="p">首页</router-link>
              >
              <!--刷新页面并且更新模块为第一块-->
              <router-link class="link" :to="{path:switchList.link,query:{index:0}}" tag="p">{{switchList.text}}
              </router-link>
              >
              <p class="linkLast">{{showTitle}}</p>
            </div>
          </div>
          <!--内容块-->
          <!--人才培养-->
          <div class="WapBoxPadding foster" v-show="activeIndex==0">
            <p class="fosterIntro">
              {{cultivate.introduce}}
            </p>
            <div class="fosterPlan">
              <p class="fosterPlanTitle">{{cultivate.title_1}}</p>
              <p class="fosterPlanValue" v-html="cultivate.title_1_nei"></p>
            </div>
            <div class="fosterPlan">
              <p class="fosterPlanTitle">{{cultivate.title_2}}</p>
              <p class="fosterPlanValue">{{cultivate.title_2_nei}}</p>
            </div>


            <!--培训-->
            <div v-if="cultivate">
              <div class="training d-none d-lg-flex">
                <div class="trainBox2" v-if="cultivate.block_1_nei">
                  <p class="trainTitle">{{cultivate.block_1_title}}</p>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训时间：</p>
                    <p class="trainValueValue">{{cultivate.block_1_nei[0]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训主题：</p>
                    <p class="trainValueValue">{{cultivate.block_1_nei[1]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训目的：</p>
                    <p class="trainValueValue">{{cultivate.block_1_nei[2]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训导师：</p>
                    <p class="trainValueValue">{{cultivate.block_1_nei[3]}}</p>
                  </div>
                </div>
                <div class="trainBox2" v-if="cultivate.block_2_nei">
                  <p class="trainTitle">{{cultivate.block_2_title}}</p>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训时间：</p>
                    <p class="trainValueValue">{{cultivate.block_2_nei[0]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训主题：</p>
                    <p class="trainValueValue">{{cultivate.block_2_nei[1]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训目的：</p>
                    <p class="trainValueValue">{{cultivate.block_2_nei[2]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训导师：</p>
                    <p class="trainValueValue">{{cultivate.block_2_nei[3]}}</p>
                  </div>
                </div>
                <div class="trainBox2" v-if="cultivate.block_3_nei">
                  <p class="trainTitle">{{cultivate.block_3_title}}</p>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训时间：</p>
                    <p class="trainValueValue">{{cultivate.block_3_nei[0]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训主题：</p>
                    <p class="trainValueValue">{{cultivate.block_3_nei[1]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训目的：</p>
                    <p class="trainValueValue">{{cultivate.block_3_nei[2]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训导师：</p>
                    <p class="trainValueValue">{{cultivate.block_3_nei[3]}}</p>
                  </div>
                </div>
                <div class="trainBox2" v-if="cultivate.block_4_nei">
                  <p class="trainTitle">{{cultivate.block_4_title}}</p>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训时间：</p>
                    <p class="trainValueValue">{{cultivate.block_4_nei[0]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训主题：</p>
                    <p class="trainValueValue">{{cultivate.block_4_nei[1]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训目的：</p>
                    <p class="trainValueValue">{{cultivate.block_4_nei[2]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训导师：</p>
                    <p class="trainValueValue">{{cultivate.block_4_nei[3]}}</p>
                  </div>
                </div>
                <img class="trainBGICoat" src="~assets/images/img/trainingBGI.jpg"/>
              </div>
              <div class="wapTraining  d-block d-lg-none">
                <div class="wapTrainingBox"  v-if="cultivate.block_1_nei">
                  <p class="trainTitle">{{cultivate.block_1_title}}</p>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训时间：</p>
                    <p class="trainValueValue">{{cultivate.block_1_nei[0]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训主题：</p>
                    <p class="trainValueValue">{{cultivate.block_1_nei[1]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训目的：</p>
                    <p class="trainValueValue">{{cultivate.block_1_nei[2]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训导师：</p>
                    <p class="trainValueValue">{{cultivate.block_1_nei[3]}}</p>
                  </div>
                </div>
                <div class="wapTrainingBox"  v-if="cultivate.block_2_nei">
                  <p class="trainTitle">{{cultivate.block_2_title}}</p>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训时间：</p>
                    <p class="trainValueValue">{{cultivate.block_2_nei[0]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训主题：</p>
                    <p class="trainValueValue">{{cultivate.block_2_nei[1]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训目的：</p>
                    <p class="trainValueValue">{{cultivate.block_2_nei[2]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训导师：</p>
                    <p class="trainValueValue">{{cultivate.block_2_nei[3]}}</p>
                  </div>
                </div>
                <div class="wapTrainingBox"  v-if="cultivate.block_3_nei">
                  <p class="trainTitle">{{cultivate.block_3_title}}</p>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训时间：</p>
                    <p class="trainValueValue">{{cultivate.block_3_nei[0]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训主题：</p>
                    <p class="trainValueValue">{{cultivate.block_3_nei[1]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训目的：</p>
                    <p class="trainValueValue">{{cultivate.block_3_nei[2]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训导师：</p>
                    <p class="trainValueValue">{{cultivate.block_3_nei[3]}}</p>
                  </div>
                </div>
                <div class="wapTrainingBox"  v-if="cultivate.block_4_nei">
                  <p class="trainTitle">{{cultivate.block_4_title}}</p>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训时间：</p>
                    <p class="trainValueValue">{{cultivate.block_4_nei[0]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训主题：</p>
                    <p class="trainValueValue">{{cultivate.block_4_nei[1]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训目的：</p>
                    <p class="trainValueValue">{{cultivate.block_4_nei[2]}}</p>
                  </div>
                  <div class="trainValueCoat">
                    <p class="trainValueTitle">培训导师：</p>
                    <p class="trainValueValue">{{cultivate.block_4_nei[3]}}</p>
                  </div>
                </div>
              </div>
            </div>
            <!--培训 END-->

            <p class="fosterPlanTitle" style="margin: 1.5rem 0 .7rem 0">员工福利</p>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-4 welfareCoat">
                <div class="welfare">
                  <div class="welfareTitleCoat">
                    <img class="welfareIco" :src="cultivate.image_first"/>
                    <p class="welfareTitle">{{cultivate.title_3_title_1}}</p>
                  </div>
                  <pre class="welfareValue" v-html="cultivate.title_3_nei_1"></pre>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-4 welfareCoat">
                <div class="welfare">
                  <div class="welfareTitleCoat">
                    <img class="welfareIco" :src="cultivate.image_two"/>
                    <p class="welfareTitle">{{cultivate.title_3_title_2}}</p>
                  </div>
                  <pre class="welfareValue" v-html="cultivate.title_3_nei_2"></pre>
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-4 welfareCoat">
                <div class="welfare">
                  <div class="welfareTitleCoat">
                    <img class="welfareIco" :src="cultivate.image_three"/>
                    <p class="welfareTitle">{{cultivate.title_3_title_3}}</p>
                  </div>
                  <pre class="welfareValue" v-html="cultivate.title_3_nei_3"></pre>
                </div>
              </div>
            </div>
          </div>
          <!--人才培养 END-->
          <!--热赵岗位-->
          <div class="WapBoxPadding" v-show="activeIndex==1">
            <div class="careerBox" v-for="(item,index) in jobList">
              <div class="careerBoxTop" @click="setJobActiveIndex(index)">
                <p class="careerBoxTitle">{{item.job}}</p>
                <div class="careerBoxTopInfo">
                  <p class="careerBoxTopParams">招聘人数：{{item.job_count}}</p>
                  <p class="careerBoxTopParams">工作地点：{{item.job_adress}}</p>
                  <img class="careerBoxTopBut" :class="{activeCareerBoxTopBut:jobActiveIndex==index}"
                       src="~assets/images/icon/redmore.png"/>
                </div>
              </div>
              <!--              &lt;!&ndash;内容&ndash;&gt;v-show="jobActiveIndex==index"-->
              <div class="careerBoxInfo" v-show="jobActiveIndex==index">
                <div class="careerBoxInfoItem">
                  <p class="careerBoxInfoTitle">岗位职责</p>
                  <pre class="careerBoxInfoValue" v-html="item.job_nei"></pre>
                </div>
                <div class="careerBoxInfoItem">
                  <p class="careerBoxInfoTitle">任职要求</p>
                  <pre class="careerBoxInfoValue" v-html="item.job_req"/>
                </div>
                <div class="careerBoxInfoItem">
                  <p class="careerBoxInfoTitle">招聘咨询</p>
                  <pre class="careerBoxInfoValue" v-html="item.job_ser"/>
                </div>
              </div>
            </div>
            <main-pager :of-pages="ofPage" @pagerPage="pagerPage"/>
          </div>
          <!--热赵岗位 END-->
          <!--内容块 END-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SwitchBar from "../../components/content/SwitchBar";
  import MainPager from "../../components/common/MainPager";
  import {getCultivate, getJob} from 'network/careerdeve';

  export default {
    name: "CareerDeve",
    components: {MainPager, SwitchBar},
    data() {
      return {
        //总条数
        total: null,
        //一页的条数
        limit: 5,
        //人才培养数据
        cultivate: {},
        //当前活跃的块下标
        activeIndex: 0,
        //热招岗位活跃的下标
        jobActiveIndex: -1,
        jobList: [],
        welfare: [],
      }
    },
    methods: {
      switchItem(index) {
        if (index != this.$route.query.index) {
          this.$router.push({
            path: this.$route.path,
            query: {
              index: index
            }
          })
        }
      },
      //  热招岗位
      getJob(page, limit) {
        getJob(page, limit).then(res => {
          this.jobList = res.data.hot_job;
          this.total = res.data.activity_total;
        })
      },
      //  分页发生变化时：
      pagerPage(page) {
        this.getJob(page, this.limit);
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        })
      },
      setJobActiveIndex(index) {
        if (this.jobActiveIndex == index) {
          this.jobActiveIndex = -1;
        } else {
          this.jobActiveIndex = index;
        }
      },
    },
    computed: {
      switchList() {
        //从vuex中取出对应的数据 ==>store ==>index
        return this.$store.state.navInfo[5];
      },
      // 当前活跃块的中文标题
      showTitle() {
        return this.switchList.childList[this.activeIndex].title
      },
      // 当前活跃块的英文标题
      showTitleE() {
        return this.switchList.childList[this.activeIndex].titleE
      },
      ofPage() {
        if (this.total) {
          return Math.ceil(Number(this.total) / this.limit);
        }
      }
    },
    created() {
      this.activeIndex = this.$route.query.index;
      getCultivate().then(res => {
        var a = res.data.Development;
        a.title_1_nei = a.title_1_nei.replace(/\r\n/g, '<br/>');
        this.cultivate = a;
      });
      this.getJob(1, this.limit);
    },
    watch: {
      '$route'() {
        this.activeIndex = this.$route.query.index;
      }
    }
  }
</script>
<style>
  .richText img {
    width: 100%;
    height: auto;
    margin-bottom: .4rem;
  }

  .richText p {
    font-size: .35rem;
    color: #a6a6a6;
    margin-bottom: .4rem;
  }
</style>
<style scoped>
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .6rem 3%;
      border-bottom: 1px solid #e2e2e2;
    }

    .WapBoxPadding {
      padding: 0 3%;
    }

    .trainBox {
      align-items: flex-start !important;
      padding: .6rem 2.4rem .6rem .45rem !important;
      margin-bottom: .6rem !important;
      border-radius: 10px;
    }
  }

  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: .35rem;
      border-bottom: 1px solid #e2e2e2;
    }

    .rightTitleValueC {
      margin-bottom: .2rem;
    }
  }
</style>
<style scoped>
  .training {
    width: 978px;
    min-height: 425px;
    max-height: 425px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .trainBGICoat {
    position: absolute;
    width: 978px;
    height: auto;
    text-align: center;
    display: flex;
  }

  .trainBox2 {
    position: relative;
    width: 40%;
    z-index: 10;
    line-height: 23px;
  }

  .trainBox2:nth-of-type(2n) {
    z-index: 10;
    padding: 0 3% 0 9%;
    /*width: 50%;*/
  }

  .trainBox2:nth-of-type(1), .trainBox2:nth-of-type(2) {
    margin-bottom: 70px;
    margin-top: 25px;
  }

  .trainBox2:nth-of-type(1), .trainBox2:nth-of-type(3) {
    padding: 0 9% 0 3%;
  }

  .trainValueCoat {
    display: flex;
    align-items: flex-start;
    font-size: .33rem;
    color: #494949;
  }

  .trainValueTitle {
    width: 75px;
  }

  .trainValueValue {
    flex: 1;
  }

  .wapTrainingBox {
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    padding: .3rem;
    margin-bottom: .6rem;
  }

  .train {
    position: relative;
    justify-content: space-between;
  }


  .trainBGI {
    /*width:260px;*/
    width: 32%;
    min-width: 220px;
    height: auto;
  }

  .trainBox {
    min-width: 360px;
    max-width: 360px;
    z-index: 2;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .trainBox:nth-child(1), .trainBox:nth-child(2) {
    margin-bottom: 36px;
  }

  .trainBox:nth-child(1), .trainBox:nth-child(3) {
    padding: 20px 66px 20px 18px;
    margin-bottom: 36px;
  }

  .trainBox:nth-child(2), .trainBox:nth-child(4) {
    padding: 20px 18px 16px 66px;
    margin-bottom: 36px;
  }

  .trainBox:nth-child(2), .trainBox:nth-child(4) {
    display: flex;
    flex-direction: column;
    /*align-items: flex-end;*/
  }

  .trainTitle {
    font-size: .45rem;
    font-weight: bold;
    color: #4c4c4c;
    margin-bottom: .18rem;
  }

  .trainValue {
    font-size: .35rem;
    color: #494949;
    display: flex;
    flex-wrap: wrap;
    line-height: 24px;
    /*
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; !* 将对象作为弹性伸缩盒子模型显示 *!
    -webkit-line-clamp: 4; !* 控制最多显示几行 *!
    -webkit-box-orient: vertical; !* 设置或检索伸缩盒对象的子元素的排列方式 *!*/
  }

  .fosterIntro {
    font-size: .4rem;
    color: #8c8c8c;
    margin-bottom: .6rem;
  }

  .fosterPlan {
    margin-bottom: .5rem;
  }

  .fosterPlanTitle {
    font-size: .45rem;
    color: #4c4c4c;
    padding-left: .4rem;
    margin-bottom: .6rem;
    border-left: 4px solid var(--red);
  }

  .fosterPlanValue {
    font-size: .4rem;
    line-height: .6rem;
    color: #494949;
  }

  .welfareCoat {
    padding-right: .33rem;
    margin-bottom: .6rem;
  }

  .welfare {
    padding: 0;
    /*background-color: #fafafa;*/
  }

  .welfareTitleCoat {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: .79rem;
    background-color: var(--red);
    padding: .6rem 0;
  }

  .welfareIco {
    height: .7rem;
    width: auto;
    margin-right: .5rem;
  }

  .activeWelfareIco {
    display: none;
  }

  .welfareTitle {
    font-size: .5rem;
    color: #fff;
    font-weight: bold;
  }

  .welfareValue {
    font-size: .4rem;
    color: #808080;
    text-align: center;
    line-height: .9rem;
    box-shadow: 0 1px 5px rgba(35, 25, 25, 0.2);
    padding: .5rem .6rem 0.2rem .6rem;
    border-radius: 6px;
    height: 5rem;
  }


  .careerBox {
    background-color: #f7f7f7;
    padding: .7rem .35rem;
    margin-bottom: .7rem;
  }

  .careerBoxTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .careerBoxTopInfo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .careerBoxTitle {
    font-size: .45rem;
    color: #4c4c4c;
    padding-left: .35rem;
    border-left: 2px solid var(--red);
  }

  .careerBoxTopParams {
    font-size: .35rem;
    color: #999999;
    margin-right: .7rem;
  }

  .careerBoxTopBut {
    width: .55rem;
    height: auto;
    transition: all .3s linear;
  }

  .activeCareerBoxTopBut {
    transform: rotate(180deg);
    transition: transform .3s;
  }

  .careerBoxInfo {
    margin-top: .9rem;
    padding: 0 .3rem;
    overflow: hidden;
  }

  .careerBoxInfoItem {
    margin-bottom: .55rem;
  }

  .careerBoxInfoTitle {
    font-size: .35rem;
    color: #4c4c4c;
    margin-bottom: .4rem;
  }

  .careerBoxInfoValue {
    font-size: .3rem;
    color: #999999;
  }


  .banner {
    width: 100%;
    height: auto;
  }

  .about {
    padding: 1.65rem var(--switchMarginX);
  }

  .rightTitle {
    margin-bottom: .55rem;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }
</style>