import {request} from "./request";
export function getCultivate() {
  return  request({
    params:{
      route:'information/information/getDevelopment',
      information_id:9
    }
  })
};export function getJob(page,limit) {
  return  request({
    params:{
      route:'activity/activity/getactivityList',
      page,
      limit
    }
  })
};